import React from 'react'

import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const ContentReturnsTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderNL = (
    <>
      <p>Bij Up North kan je, net zoals bij de andere online webshops, jouw bestelling binnen 14 dagen&nbsp;na ontvangst retourneren.&nbsp;<br /><br /></p>
      <h3>HOEVEEL KOST HET OM JOUW PAKKET TERUG TE STUREN?</h3>
      <p>Als je jouw bestelling zelf terugbrengt naar onze winkel, dan is het terugsturen <strong>gratis</strong>. Je vindt ons op het adres: Nieuwstraat 100, 2440 Geel.&nbsp;</p>
      <p>Woon je te ver van onze winkel? Dan betaal je binnen de Benelux voor het terugsturen van decoratie-items <strong>€4 retourkosten</strong>. Zodra we jouw pakket hebben ontvangen en goedgekeurd, dan&nbsp;storten we&nbsp;je jouw betaalde bedrag min €4 terug.</p>
      <p><em>Opgelet: Dit geldt niet voor meubels. Wil je een meubelstuk retourneren? Contacteer ons dan op hello@shopupnorth.com en dan geven wij je de kosten voor het&nbsp;retourneren door. Je betaalt max. €100 retourkosten, maar met minder fragiele items kom je vaak goedkoper uit.&nbsp;&nbsp;</em></p>
      <h3><br />HOE KAN JE EEN BESTELLING TERUGSTUREN?</h3>
      <h4><br />Ik heb een account op shopupnorth.be:</h4>
      <ul>
        <li>Log in op shopupnorth.be, en ga&nbsp;naar de Bestelgeschiedenis&nbsp;</li>
        <li>Vink de items aan die je wil retourneren</li>
        <li>Klik op de button "Vraag een retour"&nbsp;</li>
      </ul>
      <p>Daarna krijg je van ons een mailtje met een retourformulier en een verzendlabel. Verpak de artikelen terug in de originele verpakking en&nbsp;druk&nbsp;beide papieren af: het retourformulier&nbsp;leg je in de doos, en het verzendlabel plak je op de doos (over het originele verzendetiket). Geef je pakketje binnen de 3 werkdagen af bij het dichtstbijzijnde postpunt, en bewaar de track &amp; trace. Je krijgt van ons automatisch een bevestiging als we jouw retour goed hebben ontvangen.</p>
      <p></p>
      <h4>Ik heb geen account op shopupnorth.be</h4>
      <ul>
        <li>Stuur binnen de geldige termijn&nbsp;een mailtje naar&nbsp;<a href="mailto:hello@shopupnorth.com">hello@shopupnorth.com</a> met jouw ordernummer,&nbsp;mét&nbsp;vermelding van welke artikelen je wil retourneren.</li>
      </ul>
      <p>Daarna krijg je van ons een mailtje met een retourformulier en een verzendlabel. Verpak de artikelen terug in de originele verpakking en&nbsp;druk&nbsp;beide papieren af: het retourformulier&nbsp;leg je in de doos, en het verzendlabel plak je op de doos (over het originele verzendetiket). Geef je pakketje binnen de 3 werkdagen af bij het dichtstbijzijnde postpunt, en bewaar de track &amp; trace. Je krijgt van ons automatisch een bevestiging als we jouw retour goed hebben ontvangen.</p>
      <h4>Voorwaarden:&nbsp;</h4>
      <ul>
        <li>Indien het product niet in de oorspronkelijke staat verkeert, brengen we wel een administratiekost van €25 in rekening.&nbsp;</li>
        <li>Zodra we jouw geretourneerde artikelen ontvangen hebben, dan krijg je hiervan bevestiging. De verzendkosten voor het retourneren (€4 in het geval van het retourneren van kleine decoratie-artikelen) trekken we af van jouw betaald bedrag.&nbsp;Vervolgens betalen we&nbsp;jouw&nbsp;betaald bedrag min retourkosten terug.</li>
        <li>Up North behoudt zich echter wel het recht voor om geretourneerde artikelen of een terugbetaling te weigeren als de artikelen door de klant beschadigd zijn of als ze niet in overeenstemming met ons retourbeleid worden teruggestuurd. Dat lijkt ons niet meer dan logisch.&nbsp;<br /><br /></li>
      </ul>
      <h3>KAN JE ALLE PRODUCTEN TERUGSTUREN OF RUILEN?</h3>
      <p>Je kan alle producten ruilen, met uitzondering van uitverkoop-artikelen. &nbsp;<br /><br /></p>
      <h3>WAT ALS JOUW BESTELLING STUK IS&nbsp;BIJ LEVERING?</h3>
      <p>Al doen we er àlles aan om dat te vermijden, zou het altijd wel kunnen dat er hier en daar iets stuk gaat tijdens het verzenden. Als het item dat je&nbsp;ontvangt anders is dan het bestelde artikel of als het beschadigd aankomt, laat het ons dan zo snel mogelijk weten en stuur het binnen 7&nbsp;dagen na ontvangst terug. Zodra we het geretourneerde artikel ontvangen hebben, betalen we jouw&nbsp;bestelling én verzendkosten terug. Alleen verzendkosten voor retourzending per reguliere post worden terugbetaald.<br /><br /></p>
      <h3>HOE KAN JE JOUW BESTELLING ANNULEREN?</h3>
      <p>Als je een bestelling hebt geplaatst die je wil wijzigen of annuleren, stuur dan een mailtje naar hello@shopupnorth.com binnen 24 uur nadat&nbsp;jouw bestelling is voltooid. Heb je al een verzendbevestiging ontvangen?&nbsp;Als jouw bestelling&nbsp;al verzonden is, ontvang je alleen een volledige terugbetaling van de goederen (inclusief btw). Verzend- en administratiekosten worden dan niet terugbetaald.</p>
    </>
  )

  const renderEN = (
    <>
      <p>Under regulation of the Belgian Law on trade and the information and protection of consumers of 14 July 1991, the purchaser is allowed to inform the seller that he/she has decided not to proceed with the purchase within 14 working days of receipt of the item.</p>
      <p>The item must be returned in its original packaging with any enclosed documentation within 3 working days after this announcement. If the product is not in its original condition we will charge a handling fee of 25 Euro</p>
      <p>We will refund your order as soon as we have received the returned item. The shipping costs to return the item are at buyer’s charge</p>
      <p>If the item you receive is different from the item ordered, or if it arrives damaged, please return it within&nbsp;14 days of receipt. Upon receipt of the returned item, we will refund your order as well as your shipping fees. Only shipping costs for return via regular post will be refunded.</p>
      <p>The Shop reserves the right to reject returned items or refuse a refund if the items have been damaged by the customer or if they are not returned in accordance with our Return Policy.</p>
      <p>Ordered items that are not in stock, such as tables, chairs and sofas can't be refunded as well.</p>
      <p>Sale items can't be returned or exchanged.</p>
      <p>You will be asked to send the products back to the address below:</p>
      <p>Up North<br />Nieuwstraat 100<br />2440 Geel<br />Belgium</p>
      <p>The Shop reserves the right to reject returned items or refuse a refund if the items have been damaged by the customer or if they are not returned in accordance with our Return Policy.</p>
      <p><em>Take note: this is not valid for furniture. Want to return furniture? Contact us at hello@shopupnorth.com and we'll provide you with the return fee. You will pay max. €100 return fee.</em></p>
      <h2>CANCELLATION</h2>
      <p>If you have placed an order that you wish to change or cancel, please contact us by phone (see phone number at contact details) within 3 days after the order has been done. Once your order has been shipped, you will receive a full refund for the value of the merchandise (incl. VAT) only. Shipping and handling costs will not be refunded.</p>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentReturnsTemplate
